import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryTitle from "../components/index/categorytitle"
import { Link } from "gatsby"
import AsideItem from "../components/aside/item"

const Gizlilik = () => (
  <Layout>
    <SEO title="Gizlilik" />
    <div id="kurumsal" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15">
        <CategoryTitle title="SERENAY" subTitle="Gizlilik & Güvenlik" description="Gizlilik & Güvenlik" isDark={false} position="inline" />
        <div className="row">
        	<div className="w20 left pt15 mt15 col-sm-w100 pr45 col-sm-pr0">
        		<aside className="w100 left pos-r">
        			<ul className="w100 left fs14 fw6" style={{letterSpacing: "0.5px"}}>
			        	<AsideItem to="/kurumsal" title="Bizim Hikayemiz" icon="icon-serenay" />
			        	<AsideItem to="/kimlik" title="Kurumsal Kimlik" icon="icon-kariyer1" />
			        	<AsideItem to="/gizlilik" title="Gizlilik Güvenlik" icon="icon-shield2" />
			        </ul>
        		</aside>
        	</div>
        	<div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt15 mt15 pl45">
	        	<article className="w100 left fs18">
	  					<p><b>Serenay</b> , Üye ve Kullanıcı’larına ait bilgilerin gizliliğini ve güvenliğini sağlama hususuna özel bir önem göstermektedir. Bu husus <b>Serenay</b> ’ın bu konudaki hassasiyetinin gereği olmasının yanı sıra, ilgili yasal mevzuat uyarınca da <b>Serenay</b> ’ın yasal bir yükümlülüğüdür. Üye’lerimize ait bilgilerin korunması ve yasal mevzuata uygun davranma konusunda azami özen gösterilmesine dair yaklaşımımız çerçevesinde, tüm çalışanlarımızın bu konuda gereken hassasiyeti en yüksek düzeyde göstermesini sağlamak için, <b>Serenay</b> mevzuatında bu hususta detaylı düzenlemelere gidilmekte, yürütülen her türlü uygulama ve süreçte Üye bilgilerinin korunması hususu önemle göz önünde bulundurulmaktadır.</p>
	  					<p>Site’nin ziyaret edilmesi sırasında otomatik olan veya olmayan yöntemlerle kaydettiğimiz ve elektronik olarak bizimle paylaştığınız kişisel verileriniz, öncelikle taleplerinizin yerine getirilmesini temin edebilmek, daha sonra ise <b>Serenay</b> olarak size daha iyi hizmet sunmak amacıyla kullanılacaktır. Ayrıca Site’de bulunan Gizlilik Politikası (“Gizlilik Politikası”) hükümleri doğrultusunda, kişisel verilerinizin korunması ve güvenliği ile ilgili gereken özeni göstermekteyiz.</p>
	  					<p>Üye’lerimize ait kişisel veriler dahil olmak üzere tüm bilgiler Üye’nin onayı olmaksızın, ilgili yasal mevzuat hükümlerinin öngördüğü biçimde bu bilgilere erişme yetkisi olan yasal kurumlar ve yetkililer hariç olmak üzere hiçbir şekil ve surette üçüncü taraflarla paylaşılmamaktadır.</p>
	  					<p>Bu politikada yer verilen taahhütler işbu Site’de ve <b>Serenay</b> diğer kanalları aracılığıyla paylaşılan bilgiler için geçerli olup bu sitede linki verilen web sitelerinde ve diğer web sitelerinde ziyaret edilen web sitelerinin gizlilik ilkeleri, etik ilkeleri ve kullanım şartları geçerli olup bu sitelerde uğranabilecek maddi/manevi kayıplardan <b>Serenay</b> sorumlu değildir.</p>
	  					<p>Şahsi bilgilerinizi (adres, telefon, faks, e-posta adresi vb.) <b>Serenay</b> ile paylaşmanız halinde söz konusu bilgiler sadece talep ettiğiniz hizmetin sunulması, değişiklik, bilgilendirme, kullanım ve bakım hizmetleri ile sözleşmenin yenilenmesine ilişkin iletileri göndermek için kullanılacak ve yasal zorunluluklar veya izniniz haricinde kesinlikle 3. şahıs ve kurumlarla paylaşılmayacaktır. Onaylı veri tabanımızda yer almanız halinde ise, tarafınıza Site ürün ve hizmetleriyle ilgili olarak tanıtım, pazarlama ve promosyon faaliyetleri yapılması için ileti gönderilebilecektir. <b>Serenay</b> tarafından gönderilen e-iletileri almak istemiyorsanız, size sunulan reddetme hakkını kullanarak örneğin; Üye Ol sayfasının alt kısmında yer alan “<b>Serenay</b>" tarafından gönderilecek ticari elektronik iletileri almak istiyorum.” kutucuğunu boş bırakarak, sonrasında ise tarafınıza iletilecek onay mailinde <a href="https://www.serenay.net.tr">www.serenay.net.tr</a> yeni ürün ve hizmet tanıtımına ilişkin herhangi bir e-posta almak istemiyorsanız lütfen tıklayınız.” linkine tıklayarak, ilgili kanala ait listemizden çıkabilirsiniz.</p>
	  					<p>Kişisel Verilerin Korunması</p>
	  					<p>Bu Gizlilik Politikası'nda, “kişisel verileriniz,” kimliğinizi belirli ya da belirlenebilir kılan her türlü bilgi veya bilgi parçalarını ifade eder.</p>
	  					<p>Kişisel verilerinizin elde edilmesi, kaydedilmesi, saklanması, muhafazası, değiştirilmesi, yeniden düzenlenmesi, açıklanması, satılması ya da diğer biçimlerde işlenmesi bakımından aşağıda yer alan ilkelere <b>Serenay</b> olarak uygun davranacağımızı taahhüt ederiz.</p>
	  					<p>Kişisel verileriniz, her zaman hukuka ve dürüstlük kuralına uygun, doğru bir biçimde işlenecektir.</p>
	  					<p>Kişisel verileriniz, rızanızın alınması suretiyle ya da mevzuatta öngörülen diğer hallerde işlenebilecek ve/veya üçüncü kişilere aktarılabilecektir.</p>
	  					<p>Kişisel verileriniz, belirli, açık ve meşru amaçlar için işlenebilecektir.</p>
	  					<p>Kişisel verileriniz, anılan amaçlarla sınırlı ve bağlantılı olarak toplanacak ve ölçülü biçimde işlenecektir.</p>
	  					<p>Tarafınıza, kişisel verilerinizi gerektiğinde güncelleme imkânı sağlanacaktır.</p>
	  					<p>Kişisel verileriniz işlendikleri amaç için gerekli olan süre kadar muhafaza edilecektir</p>
	  					<p><b>BİLGİLENDİRME</b></p>
	  					<p>Sizin Tarafınızdan Paylaşılan Veriler</p>
	  					<p>Otomatik olarak elde edilen verilerin yanı sıra, sizin tarafınızdan paylaşılan veriler de ayrıca işlenmektedir. Bu veriler, sağlama amacınız doğrultusunda ve bu amaçla sınırlı kalmak kaydıyla kullanılmaktadır. Söz konusu verilerin kullanım amaçlarına ilişkin başlıca örnekler şu şekilde sıralanabilecektir:</p>
	  					<p>
	  						<ol>
	  							<li>Site’ye kayıt olduğunuzda kişisel verilerinizi ilgili web sitemizde yer alan formlar aracılığıyla elde eder (örneğin; ad-soyad, e-posta adresi, iletişim ve adres bilgileri vb.) ve talep edilen hizmetler sağlanır veya onayınız var ise e-bülten, pazarlamaya yönelik bilgilendirmeler vb. bültenler gönderebiliriz. Ayrıca sipariş detayı, ziyaret detayı, fatura ve teslimat adresleri gibi bazı kişisel bilgilerinize ihtiyaç duyacağız. Bu bilgiler haricinde, ziyaret sıklığı ve zamanları, sipariş verilme zamanları ve sipariş anındaki internet protokol adresi (IP) ve son oturum açma IP adresi, ziyaret edilen ürün sayfalarının ve sipariş verilen ürünlerin istatistikleri gibi toplu istatistiksel veriler, ziyaretçi hareket ve tercihlerini analiz etmek ve anlamak amacıyla toplanır ve kullanılır.</li>
	  							<li>Onayınız var ise kişisel verileriniz; pazar araştırması, planlama ve istatistiki analizler yapılması amacıyla işlenebilmektedir.</li>
	  							<li>Kullanıcıların ve web sitemizin güvenliğini sağlamak adına kişisel verilerinizi işleyebiliriz.</li>
	  						</ol>
	  					</p>
	  					<p><b>Kişisel Verilerin Başkalarına İletilmesi</b></p>
	  					<p>Kişisel verileriniz rızanız olmaksızın veya mevzuatta öngörülen haller dışında bu Gizlilik Politikası’nda belirtilenler haricinde üçüncü kişilerle paylaşılmayacaktır.</p>
	  					<p>Kişisel verilerinizi <b>Serenay</b> için destek hizmetleri sağlayan üçüncü kişilerle (Bilgi işlem hizmeti veren sağlayıcılar, sitelerimizi barındıran (hosting) veya işleten; verileri analiz eden hizmet sağlayıcıları, ürün ve hizmetlerin sizlere ulaştırılmasına yardımcı olan hizmet sağlayıcıları, müşteri hizmetleri sunan, ödemeleri yöneten hizmet sağlayıcıları vb.) paylaşabiliriz. Destek hizmeti sağlayan ilgili üçüncü kişiler, söz konusu servisi yerine getirebilmek için gerekli olan ilgili kişisel veriye erişim hakkı sağlamaktadır. Bu durumda, söz konusu destek hizmeti sağlayan üçüncü kişiler, bilgilerinizi yalnızca <b>Serenay</b> adına ve <b>Serenay</b> talimatlarına göre hizmet vermek için kullanacak, sözleşmesel yükümlülükleri çerçevesinde bu bilgileri gizli tutmak ve başka hiçbir amaçla kullanmama yükümlülüğü altında olacaklardır.</p>
	  					<p>Bazı durumlarda kişisel olmayan ve anonim hale getirilmiş veriler, örneğin web sitemize belirli bir zaman periyodu içerisinde kaç ziyaret yapıldığının paylaşımı söz konusu olabilir.</p>
	  					<p>Verilerin Saklanma Süresi</p>
	  					<p><b>Serenay</b> kişisel verilerinizi, söz konusu verilerin elde edilme amaçlarına uygun olan sürece ve/veya ilgili mevzuatta öngörülen belirli bir süre dahilinde güvenli ve korunmuş bir ortamda saklayacaktır.</p>
	  					<p>Kişisel Verilerin Güvenliği</p>
	  					<p><b>Serenay</b> kişisel verilerin gizliliği, güvenliği ve bütünlüğünü korumak için gerekli ve makul korumaları sağlamakta, söz konusu verilerin herhangi bir kayba, suiistimale ya da değişikliğe karşı korunması için çok çalışmakta ve gerekli tüm önlemleri almaktadır.</p>
	  					<p>Site üzerinden alınan bilgiler SSL (Secure Socket Layer) olarak adlandırılan ve güvenli bilgi aktarımı sağlayan teknoloji ile taşınır. Site üzerinde ödeme bilgilerinizi aktardığınız sayfalarda tarayıcınızın adres satırında en sağda (kullandığınız tarayıcıya bağlı olarak) bir kilit ya da anahtar resmi ve bu adres satırında bulunan adresin ilk harflerinin http’den https’e dönüştüğünü göreceksiniz. Bunları görmeniz durumunda sitemizin ve/veya mobil uygulamamız güvenli sunucuları üzerinde olduğunuzdan emin olabilirsiniz.</p>
	  					<p>Kişisel verilerinizi genel olarak <b>Serenay</b> veri tabanlarında ya da hizmet sağlayıcılarımız tarafından sağlanan veri tabanlarında saklamaktayız.</p>
	  					<p><b>Serenay</b> , gerekli hizmeti size ulaştırmak için bilgilerinize erişimi olan sözleşmesel ortakları, sözleşmesel yükümlülükleri çerçevesinde bu bilgileri gizli tutmak ve başka herhangi amaçla kullanmamakla yükümlüdür.</p>
	  					<p>Bilgilerin güvenliği ve verinin bütünlüğü</p>
	  					<p></p>Bilgilerin güvenliği ve verinin bütünlüğü
	  					<p>
Kişisel verilerinizi, kazara veya yasadışı yok edilmelere, kayıplara, değişimlere, bilgisayar ağı üzerinden veri aktarımları sırasında oluşabilecek kanuna aykırı tüm yetkisiz kullanımlara, açığa çıkarmalara ve erişimlere ve diğer şekillerde olan işlemlere ve kötüye kullanmalara karşı korumak için teknik ve kuruluşun gerektirdiği ölçülerde önlemler aldığımızı teyit ederiz.</p>
	  					<p>Kişisel veriler ve çocuklar</p>
	  					<p>Site’de yer alan hizmetler, çocuklara yönelik olarak düzenlenmemiştir. İlgili mevzuat tarafından izin verilen durumlar hariç olmak üzere, çocuklardan ya da çocuklar hakkında web siteleri üzerinden bilerek kişisel veriler talep edilemez ya da elde edilemez.</p>
	  					<p>Gizlilik Politikamızdaki Değişiklikler</p>
	  					<p><b>Serenay</b> gizlilik ve veri koruma prensiplerini güncel tutmak ve ilgili mevzuata uygun hale getirmek için Gizlilik Politikasında önceden haber vermeksizin değişiklikler yapma hakkını saklı tutar. Gizlilik Politikasını değiştirmeye karar verdiğimiz takdirde, değişen politika web sitemizde ilan edilecektir.</p>
	  					<p><b>Haklarınız</b></p>
	  					<p>Kişisel verilerinizin işlenmesi ile ilgili vermiş olduğunuz her türlü onaydan tamamen veya kısmen feragat etme hakkınız bulunmaktadır. Ayrıca, kişisel verilerinizin işlenip işlenmediğini; işlenmişse buna ilişkin bilgi talep etme, hangi kişisel verilerinizin işlendiğini; işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını; yurtiçinde veya yurtdışında bilgilerin üçüncü kişilere aktarılıp aktarılmadığını ve aktarıldı ise bu üçüncü kişileri ya da kişi kategorilerini öğrenmek; kişisel verilerinizin eksik veya yanlış olması halinde bunların düzeltilmesini; değişmesi halinde güncellenmesini; işlemeyi gerektiren sebeplerin ortadan kalkması halinde kişisel verilerinizin silinmesi ya da yok edilmesini; düzeltme/silme/yok etme işlemlerinin, kişisel verilerinizin aktarıldığı 3. kişilere bildirilmesini talep etmek; işlenen kişisel verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etmek ve kişisel verilerin mevzuata aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep etme hakkınız, <b>Serenay</b> ’ın mevzuattan ve işbu Politika’dan doğan hakları saklı kalmak üzere, bulunmaktadır. Makul olmayacak düzeyde yinelenen, orantısız düzeyde teknik çaba gerektiren, başkalarının gizliliğini tehlikeye atan veya başka şekilde aşırı derecede zor olan istekleri reddetme hakkımızın saklı olduğunu belirtmek isteriz.</p>
	  					<p>Bu kapsamda yukarıda belirtilen haklarınızı kullanmak için kimliğinizi ve iletişim bilgilerinizi tespit edici gerekli bilgiler ile KVKK’nın 11. maddesinde belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren her türlü talebinizi destek@serenay.net.tr adresine iletebilirsiniz veya aşağıda belirtilen diğer iletişim yollarına başvurabilirsiniz.</p>
	  					<p><b>Adres Bilgileri</b></p>
	  					<p>
	  						<ul>
	  							<li>Konak, Elit Park, Kudret Sk. No: 12 D:Kat 3 Daire 17, 16140 <b>Nilüfer/Bursa</b></li>
	  							<li>Telefon: +90 224 413 86 76</li>
	  							<li>E-mail: destek@serenay.net.tr</li>
	  						</ul>
	  					</p>
	  					<p><b>Çerez Politikası</b></p>
	  					<p>Güncelleme tarihi: 22.06.2022</p>
	  					<p>Çerezler nedir?</p>
	  					<p>Günümüzde neredeyse her web sitesi çerez kullanmaktadır. Size daha iyi, hızlı ve güvenli bir deneyim sağlamak için, çoğu web sitesi gibi <b>Serenay</b> de çerez kullanmaktadır.</p>
	  					<p>Çerezler, herhangi bir internet sitesi tarafından bilgisayara bırakılan bir tür tanımlama dosyalarıdır. Site tekrar ziyaret edildiğinde bilgisayarların tanınmasını sağlar.</p>
	  					<p>Çerezler, internet sitesinin ziyaretinizle ilgili bilgileri (örneğin, tercih ettiğiniz dil ve diğer ayarlar) hatırlamasına yardımcı olur. Bir sonraki ziyaretinizde kolaylık sağlayabilir ve siteyi daha kullanışlı hale getirebilir.</p>
	  					<p><b>Serenay</b> ’ın Çerez Kullanımı</p>
	  					<p>Site’yi ziyaret ederek <b>Serenay</b> ’ın Site’nin kullanımı için gerekli olan çerezlerin cihazınıza yerleştirmesini kabul edersiniz.</p>
	  					<p><b>Serenay</b> çerezler sayesinde topladığı bilgileri işbu Gizlilik Politikası doğrultusunda;</p>
	  					<p>Ziyaret ettiğiniz sitenin daha iyi hizmet sunabilmesi adına ziyaretçilerin kullanım alışkanlıklarını ve tercihlerini saptama,</p>
	  					<p>Sitedeki kullanıcı deneyiminin artırılması için ihtiyaç duyulan teknik verilerin belirlenmesi ve toplanması,</p>
	  					<p>5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun ve İnternet Ortamında Yapılan Yayınların Düzenlenmesine Dair Usul ve Esaslar Hakkında Yönetmelik gereğince kanuni olarak yükümlülüklerin yerine getirebilmesi amacıyla ziyaretçilerin IP adresi gibi bilgilerine ihtiyaç duyulması amacıyla kullanır.</p>
	  					<p><b>Serenay</b> ’ın cihazınıza çerezler yerleştirmesini istemiyorsanız tarayıcınızın ayarları veya seçenekleri vasıtasıyla çerezlerin kullanımını reddedebilirsiniz. Bu durumda Site’nin bazı alanlarını düzgün biçimde kullanamayabilirsiniz.</p>
	  					<p><b>Serenay</b> gerekli gördüğü durumlarda, işbu Çerez (Cookie) Politikasını değiştirebilir. Çerez Politikasının değiştirildiği hallerde sayfanın üst kısmındaki güncelleme tarihinden itibaren geçerli olacaktır. Çerez (Cookie) Politikası’nı belirli zamanlarda inceleyerek, çerez kullanımı ile ilgili bilgi edinmenizi tavsiye ederiz.</p><b>Serenay</b> gerekli gördüğü durumlarda, işbu Çerez (Cookie) Politikasını değiştirebilir. Çerez Politikasının değiştirildiği hallerde sayfanın üst kısmındaki güncelleme tarihinden itibaren geçerli olacaktır. Çerez (Cookie) Politikası’nı belirli zamanlarda inceleyerek, çerez kullanımı ile ilgili bilgi edinmenizi tavsiye ederiz.
	        	</article>
	        </div>
        </div>        
      </div>
    </div>
  </Layout>
)

export default Gizlilik
